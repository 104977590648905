import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;
@Injectable({
    providedIn: 'root'
})
export class StorageService {
    public login: number = 0;
    public IdUsuario: number;
    public Nombre: string;
    public Email: string;
    public Domicilio: string;

    constructor() {}

    public async validaSesionOnLoad() {
        const ret = await Storage.get({ key: 'EmailDataLaSalle' });
        const Data = JSON.parse(ret.value);
        if (Data) {
            this.login = 1;
            this.IdUsuario = Data['IdUsuario'];
            this.Nombre = Data['Nombre'];
            this.Email = Data['Email'];
            this.Domicilio = Data['Domicilio'];
            return 1;
        }
        return 0;
    }

    public async setSesionData(dataLogin) {
        await Storage.set({
            key: 'EmailDataLaSalle',
            value: JSON.stringify({
                login: 1,
                IdUsuario: dataLogin['IdUsuario'],
                Nombre: dataLogin['Nombre'],
                Email: dataLogin['Email'],
                Domicilio: dataLogin['Domicilio'],
            })
        });
        this.login = 1;
        this.IdUsuario = dataLogin['IdUsuario'];
        this.Nombre = dataLogin['Nombre'];
        this.Email = dataLogin['Email'];
        this.Domicilio = dataLogin['Domicilio'];
        return 1;
    }

    public async removeSesion() {
        this.login = 0;
        this.IdUsuario = null;
        this.Nombre = '';
        this.Email = '';
        this.Domicilio = '';
        await Storage.clear();
        return 1;
    }

    public getLogin() {
        return this.login;
    }

    public getIdUsuario() {
        return this.IdUsuario;
    }

    public getNombre() {
        return this.Nombre;
    }

    public getEmail() {
        return this.Email;
    }

    public getDomicilio() {
        return this.Domicilio;
    }

}
