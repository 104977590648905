import { Injectable } from '@angular/core';
import { LoadingController, Platform, ToastController } from '@ionic/angular';

import { GlobalConstants } from 'src/app/services/globalConstants/global-constants.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalFunctionsService {

  private _loader: any;

  constructor(private _platform: Platform,
              private _toastCtrl: ToastController,
              public loadingCtrl: LoadingController) { }

  public runningOnDevice(): boolean {
    return !this._platform.is('mobileweb');
  }

  public formatCurrency(numero: number): string {
    return numero.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  public bankers_round(n: number, d: number = 0) {
    const x = n * Math.pow(10, d);
    const r = Math.round(x);
    const br = (((((x > 0) ? x : (-x)) % 1) === 0.5) ? (((0 === (r % 2))) ? r : (r - 1)) : r);
    return br / Math.pow(10, d);
  }

  public dateFromWebAPI(fecha: string): Date {
    const fTxt: string = fecha.substring(0, 4) + '-' + fecha.substring(4, 6) + '-' + fecha.substring(6, 8) + ' 00:00:00';
    const fDate: Date = new Date(fTxt);

    console.log('dateFromWebAPI >> fTxt >> ' + fTxt);
    console.log('dateFromWebAPI >> fDate >> ' + fDate);

    return (fDate);
  }

  public dateAs_ddMMMyyyy(fecha: Date): string {
    const monthNames = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'];

    return ('0' + (fecha.getDate())).slice(-2).toString() + '/' +
      monthNames[fecha.getMonth()] + '/' +
      fecha.getFullYear().toString();
  }

  public dateAs_yyyyMMdd(fecha: Date): string {
    return fecha.getFullYear().toString() +
      ('0' + (fecha.getMonth() + 1)).slice(-2).toString() +
      ('0' + (fecha.getDate())).slice(-2).toString();
  }

  public dateAs_yyyyMM(fecha: Date): string {
    return fecha.getFullYear().toString() +
      ('0' + (fecha.getMonth() + 1)).slice(-2).toString();
  }

  public dateAs_MMMyyyy(fecha: Date): string {
    const monthNames = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dec'];
    return monthNames[fecha.getMonth()] + ' ' +
      fecha.getFullYear().toString();
  }

  public dateAs_yyyyMMdd_HHmmss(fecha: Date): string {
    return fecha.getFullYear().toString() +
      ('0' + (fecha.getMonth() + 1)).slice(-2).toString() +
      ('0' + (fecha.getDate())).slice(-2).toString() + '_' +
      ('0' + (fecha.getHours())).slice(-2).toString() +
      ('0' + (fecha.getMinutes())).slice(-2).toString() +
      ('0' + (fecha.getSeconds())).slice(-2).toString();
  }

  public dateAs_ddMMyyyy_HHmmss(fecha: Date): string {
    return ('0' + (fecha.getDate())).slice(-2).toString() + '/' +
      ('0' + (fecha.getMonth() + 1)).slice(-2).toString() + '/' +
      (fecha.getFullYear()).toString() + ' ' +
      ('0' + (fecha.getHours())).slice(-2).toString() + ':' +
      ('0' + (fecha.getMinutes())).slice(-2).toString() + ':' +
      ('0' + (fecha.getSeconds())).slice(-2).toString();
  }

  public dateToWebAPI(fecha: Date): string {
    return fecha.getFullYear().toString() +
      ('0' + (fecha.getMonth() + 1)).slice(-2).toString() +
      ('0' + (fecha.getDate())).slice(-2).toString();
  }

  public setIconsCategoria(allItems: Array<any>) {

    try {

      // hace loop para poner iconos y colores
      allItems.forEach((item) => {
        switch (item.IdCategoria) {
          case GlobalConstants.CATEGORIA_PASTEL: {
            item.ImgUrl32x32 = 'cake01.primary.32x32.png';
            item.ImgUrl24x24 = 'cake01.primary.24x24.png';
            item.ImgUrl16x16 = 'cake01.primary.16x16.png';
            break;
          }
          case GlobalConstants.CATEGORIA_PAY: {
            item.ImgUrl32x32 = 'pay01.primary.32x32.png';
            item.ImgUrl24x24 = 'pay01.primary.24x24.png';
            item.ImgUrl16x16 = 'pay01.primary.16x16.png';
            break;
          }
          case GlobalConstants.CATEGORIA_EMPANADA: {
            item.ImgUrl32x32 = 'empanada01.primary.32x32.png';
            item.ImgUrl24x24 = 'empanada01.primary.24x24.png';
            item.ImgUrl16x16 = 'empanada01.primary.16x16.png';
            break;
          }
          case GlobalConstants.CATEGORIA_REPOSTERIA: {
            item.ImgUrl32x32 = 'reposteria01.primary.32x32.png';
            item.ImgUrl24x24 = 'reposteria01.primary.24x24.png';
            item.ImgUrl16x16 = 'reposteria01.primary.16x16.png';
            break;
          }
          case GlobalConstants.CATEGORIA_QUEQUITOS: {
            item.ImgUrl32x32 = 'quequito01.primary.32x32.png';
            item.ImgUrl24x24 = 'quequito01.primary.24x24.png';
            item.ImgUrl16x16 = 'quequito01.primary.16x16.png';
            break;
          }
          case GlobalConstants.CATEGORIA_REBANADAS_INDIVIDUALES: {
            item.ImgUrl32x32 = 'rebanada01.primary.32x32.png';
            item.ImgUrl24x24 = 'rebanada01.primary.24x24.png';
            item.ImgUrl16x16 = 'rebanada01.primary.16x16.png';
            break;
          }
          case GlobalConstants.CATEGORIA_PAN: {
            item.ImgUrl32x32 = 'pan01.primary.32x32.png';
            item.ImgUrl24x24 = 'pan01.primary.24x24.png';
            item.ImgUrl16x16 = 'pan01.primary.16x16.png';
            break;
          }
          case GlobalConstants.CATEGORIA_VELAS: {
            item.ImgUrl32x32 = 'vela01.primary.32x32.png';
            item.ImgUrl24x24 = 'vela01.primary.24x24.png';
            item.ImgUrl16x16 = 'vela01.primary.16x16.png';
            break;
          }
          case GlobalConstants.CATEGORIA_DESECHABLES: {
            item.ImgUrl32x32 = 'desechable01.primary.32x32.png';
            item.ImgUrl24x24 = 'desechable01.primary.24x24.png';
            item.ImgUrl16x16 = 'desechable01.primary.16x16.png';
            break;
          }
          case GlobalConstants.CATEGORIA_ARTICULOS_DECORACION: {
            item.ImgUrl32x32 = 'decoracion01.primary.32x32.png';
            item.ImgUrl24x24 = 'decoracion01.primary.24x24.png';
            item.ImgUrl16x16 = 'decoracion01.primary.16x16.png';
            break;
          }
          case GlobalConstants.CATEGORIA_COMPRA_VENTA: {
            item.ImgUrl32x32 = 'compraVenta01.primary.32x32.png';
            item.ImgUrl24x24 = 'compraVenta01.primary.24x24.png';
            item.ImgUrl16x16 = 'compraVenta01.primary.16x16.png';
            break;
          }
          case GlobalConstants.CATEGORIA_OTROS_ALIMENTOS: {
            item.ImgUrl32x32 = 'food01.primary.32x32.png';
            item.ImgUrl24x24 = 'food01.primary.24x24.png';
            item.ImgUrl16x16 = 'food01.primary.16x16.png';
            break;
          }
          case GlobalConstants.CATEGORIA_OTROS: {
            item.ImgUrl32x32 = 'otros02.primary.32x32.png';
            item.ImgUrl24x24 = 'otros02.primary.24x24.png';
            item.ImgUrl16x16 = 'otros02.primary.16x16.png';
            break;
          }
          case GlobalConstants.CATEGORIA_SIN_CATEGORIA: {
            item.ImgUrl32x32 = 'sinCategoria.primary.32x32.png';
            item.ImgUrl24x24 = 'sinCategoria.primary.24x24.png';
            item.ImgUrl16x16 = 'sinCategoria.primary.16x16.png';
            break;
          }
        }
      });

    } catch (error) {
      // do nothing
    }

  }

  public distanceToPlace(lat1: number, lon1: number, lat2: number, lon2: number, unit: string) {
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') { dist = dist * 1.609344; }
    if (unit == 'N') { dist = dist * 0.8684; }
    return dist;
  }

  public showMessageToast(msg: string) {
    this._toastCtrl.create({
      message: msg,
      animated: true,
      duration: 3000,
      color: 'pinkWhite',
      position: 'bottom',
      keyboardClose: true,
    }).then((toast) => {
      toast.present();
    });
  }

  // Region Loader
  public async showLoader() {
    this.loadingCtrl.create({
      message: 'Cargando',
    }).then((res) => {
      res.present();
  
      res.onDidDismiss().then((dis) => {
        console.log('Loading dismissed! after 2 Seconds', dis);
      });
    });
  }

  public hideLoader() {
    console.log('cerrando');
    try {
      this.loadingCtrl.dismiss().then((res) => {
        console.log('Loading dismissed!', res);
      }).catch((error) => {
        console.log('error', error);
      });
    } catch (error) {
    }
  }
}
