// tslint:disable: max-line-length
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WebAPIService } from 'src/app/services/webapi/web-api.service';
import { WebAPIConstants } from 'src/app/services/globalConstants/global-constants.service';

import { DeviceInfoService } from 'src/app/services/deviceInfo/device-info.service';

import { IVisita } from 'src/app/interfaces/IVisita';

@Injectable({
  providedIn: 'root'
})
export class VisitaService {

//#region Constructor

  constructor(public _platform: Platform,
              private _webAPISrv: WebAPIService,
              private _deviceInfoSrv: DeviceInfoService) {

  }

//#endregion Constructor

  public guardar(urlVisita: string, usuario: string,
                 idSucursal: string,
                 idCategoriaWeb: string, idProductoWeb: number,
                 idProducto: number, codigoProducto: string,
                 busquedaTxt: string, mensaje: string,
                 utmCampaign: string, utmContent: string,
                 utmMedium: string, utmSource: string, utmTerm: string): Observable<any> {

    // construye estructura para registar visita
    const newVisita: IVisita = {
      Usuario: usuario,
      URL: urlVisita,
      IdSucursal: idSucursal,
      IdCategoriaWeb: idCategoriaWeb,
      IdProductoWeb: idProductoWeb,
      IdProducto: idProducto,
      CodigoProducto: codigoProducto,
      //
      Busqueda: busquedaTxt,
      Mensaje: mensaje,
      //
      DeviceInfo: this._deviceInfoSrv.CurrentDeviceInfo,
      utm_campaign: utmCampaign,
      utm_content: utmContent,
      utm_medium: utmMedium,
      utm_source: utmSource,
      utm_term: utmTerm,
      //
      Favorito: null
    };

    // guarda visita en API
    const url: string = WebAPIConstants.URLVisita + 'guardar';
    return this._webAPISrv.post(url, newVisita, '', false).pipe(map((idVisita: number) => {
      console.log('-------------------------------------------');
      console.log('VisitaService >> guardar >> ' + urlVisita + ' >> ' + idVisita);
      console.log('-------------------------------------------');
    }));

  }

  public guardarFavorito(urlVisita: string, usuario: string,
                         idSucursal: string,
                         idCategoriaWeb: string, idProductoWeb: number,
                         idProducto: number, codigoProducto: string,
                         busquedaTxt: string, mensaje: string,
                         utmCampaign: string, utmContent: string,
                         utmMedium: string, utmSource: string, utmTerm: string, favorito: boolean): Observable<any> {

    // construye estructura para registar visita
    const newVisita: IVisita = {
      Usuario: usuario,
      URL: urlVisita,
      IdSucursal: idSucursal,
      IdCategoriaWeb: idCategoriaWeb,
      IdProductoWeb: idProductoWeb,
      IdProducto: idProducto,
      CodigoProducto: codigoProducto,
      //
      Busqueda: busquedaTxt,
      Mensaje: mensaje,
      //
      DeviceInfo: this._deviceInfoSrv.CurrentDeviceInfo,
      utm_campaign: utmCampaign,
      utm_content: utmContent,
      utm_medium: utmMedium,
      utm_source: utmSource,
      utm_term: utmTerm,
      //
      Favorito: favorito
    };

    // guarda visita en API
    const url: string = WebAPIConstants.URLVisita + 'guardarFavorito';
    return this._webAPISrv.post(url, newVisita, '', false).pipe(map((idFav: number) => {
      console.log('-------------------------------------------');
      console.log('VisitaService >> guardarFavorito >> ' + idFav);
      console.log('-------------------------------------------');
    }));

  }
}
