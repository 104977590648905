import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AlertController, MenuController, ModalController, PopoverController } from '@ionic/angular';
import { CancelarPopoverComponent } from 'src/app/components/cancelarPopover/cancelar-popover.component';
import { ProductoMensajePopoverComponent } from 'src/app/components/productoMensajePopover/producto-mensaje-popover.component';
import { CarritoService } from 'src/app/services/carrito/carrito.service';
import { DeviceInfoService } from 'src/app/services/deviceInfo/device-info.service';

import { GlobalConstants } from 'src/app/services/globalConstants/global-constants.service';
import { GlobalFunctionsService } from 'src/app/services/globalFunctions/global-functions.service';
import { StorageService } from 'src/app/services/storage/storage.service';

import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { VentaService } from 'src/app/services/venta/venta.service';
import { VisitaService } from 'src/app/services/visita/visita.service';

@Component({
  selector: 'app-pedidoDetalleCliente',
  templateUrl: './pedidoDetalleCliente.page.html',
  styleUrls: ['./pedidoDetalleCliente.page.scss'],
})
export class PedidoDetalleClientePage implements OnInit {

  //#region Propiedades

  public _appVersion: string;

  public _utmCampaign: string = null;
  public _utmContent: string = null;
  public _utmMedium: string = null;
  public _utmSource: string = null;
  public _utmTerm: string = null;
  public _descStatus: string = '';

  public _pedido: any;
  public _productos: any;
  public _listProductos: any;

  //#endregion Propiedades

  //#region Constructor

  constructor(private _route: ActivatedRoute,
              private _menuCtrl: MenuController,
              private _usuarioSrv: UsuarioService,
              private _visitaSrv: VisitaService,
              public _alertController: AlertController,
              public storage: StorageService,
              private _ventaSrv: VentaService,
              private modalCtrl: ModalController,
              private _popoverCtrl: PopoverController,
              private _globalFunctionsSrv: GlobalFunctionsService, ) {

    console.log('PedidoDetalleClientModal >> constructor');
    // this._listProduct = [];
  }

  //#endregion Constructor

  //#region Eventos NG

  public ngOnInit() {
    console.log('pedido:');
    console.log(this._pedido);
    this._menuCtrl.enable(false);
  }

  public async ionViewDidEnter() {

    console.log('PedidoDetalleClientModal >> ionViewDidEnter');

    this._appVersion = GlobalConstants.APP_VERSION;

    // recupera/crea/guarda token publico
    await this._usuarioSrv.doPublicToken();

    // await this._deviceSrv.getLocationInfo();

    /*this._deviceSrv.getLocationInfo().then((domi) => {
      console.log('el domi que se va a mandar es:');
      console.log(domi);
    });*/

    // registra visita
    await this._route.queryParams.subscribe((params) => {

      // recupera datos de UTM
      if (params['utm_campaign'] !== undefined) { this._utmCampaign = params['utm_campaign']; }
      if (params['utm_content'] !== undefined) { this._utmContent = params['utm_content']; }
      if (params['utm_medium'] !== undefined) { this._utmMedium = params['utm_medium']; }
      if (params['utm_source'] !== undefined) { this._utmSource = params['utm_source']; }
      if (params['utm_term'] !== undefined) { this._utmTerm = params['utm_term']; }

      this._visitaSrv.guardar('CarritoCompras', null, null,
        null, null, null, null,
        null, null,
        this._utmCampaign, this._utmContent, this._utmMedium, this._utmSource, this._utmTerm).subscribe();
        // this.getCarrito();
    });
    await this._usuarioSrv.doPublicToken();

    if (this._pedido['TipoPedido'] == 'PIC'){ // Se recoge en tienda
      if (this._pedido['IdStatus'] == 'GEN'){
        this._descStatus = 'CONFIRMAR PEDIDO';
      }
      if (this._pedido['IdStatus'] == 'CON'){
        this._descStatus = 'PEDIDO LISTO PARA ENTREGAR';
      }
      if (this._pedido['IdStatus'] == 'LEN'){
        this._descStatus = 'PEDIDO ENTREGADO';
      }
    }
    else{ // Se entrega a domicilio
      if (this._pedido['IdStatus'] == 'GEN'){
        this._descStatus = 'CONFIRMAR PEDIDO';
      }
      if (this._pedido['IdStatus'] == 'CON'){
        this._descStatus = 'PEDIDO ENVIADO';
      }
      if (this._pedido['IdStatus'] == 'ENV'){
        this._descStatus = 'PEDIDO ENTREGADO';
      }
    }
  }

  public async close() {
    this.modalCtrl.dismiss({});
  }

  //#endregion

}
