import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Plugins } from '@capacitor/core';
const { Device } = Plugins;
const { Geolocation } = Plugins;

import { WebAPIService } from 'src/app/services/webapi/web-api.service';
import { WebAPIConstants, GlobalConstants } from 'src/app/services/globalConstants/global-constants.service';

import { IDeviceInfo } from 'src/app/interfaces/IDeviceInfo';
import { ILocationInfo } from 'src/app/interfaces/ILocationInfo';

import { google } from "google-maps";

@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {

  //#region Properties

  public CurrentDeviceInfo: IDeviceInfo = null;
  public CurrentLocation: ILocationInfo = null;

  //#endregion Properties

  //#region Constructor

  constructor(public _platform: Platform,
              private _webAPISrv: WebAPIService) {

  }

  //#endregion Constructor

  //#region Get WebAPI / Device Info

  public async getDeviceInfo(): Promise<any> {

    console.log('DeviceInfoService >> getDeviceInfo');
    
    // recupera datos del device/app desde el webAPI
    const url: string = WebAPIConstants.URLSoporte + 'getAllDeviceInfo';
    const res = await this._webAPISrv.get(url, '', '', false).toPromise().then(async (webAPIInfo: IDeviceInfo) => {

      await Device.getInfo().then(async deviceData => { // recupera datos propios del dispositivo
        await Geolocation.getCurrentPosition().then((geoData) => { // recupera posicion geografica

          this.CurrentDeviceInfo = {
            AppVersion: GlobalConstants.APP_VERSION,
            //
            WebAPIServerURL: WebAPIConstants.URLServer,
            WebAPIServerVersion: webAPIInfo.WebAPIServerVersion,
            WebAPIServerType: webAPIInfo.WebAPIServerVersion,
            //
            DBServer: webAPIInfo.DBServer,
            DBServerVersion: webAPIInfo.DBServerVersion,
            //
            DeviceUUID: deviceData.uuid,
            DeviceManufacturer: deviceData.manufacturer,
            DeviceModel: deviceData.model,
            DeviceOS: deviceData.operatingSystem,
            DeviceOSVersion: deviceData.osVersion,
            DevicePlatform: '',
            DeviceIPAddressPublic: webAPIInfo.DeviceIPAddressPublic,
            DeviceScreenResolution: this._platform.width() + 'x' + this._platform.height(),
            //
            DeviceLat: geoData.coords.latitude,
            DeviceLng: geoData.coords.longitude,
            DeviceAlt: geoData.coords.altitude,
            DeviceAcu: geoData.coords.accuracy,
            DeviceSpeed: geoData.coords.speed,
            DeviceHeading: geoData.coords.heading,
            //
            BrowserName: webAPIInfo.BrowserName,
            BrowserVersion: webAPIInfo.BrowserVersion,
            //
            Token: ''
          };

          // da formato las plataformas desde _platform
          this.CurrentDeviceInfo.DevicePlatform += ' ('; // agrega parentesis
          this._platform.platforms().forEach((itemP)  => { // recorre para concatenar en un string
            this.CurrentDeviceInfo.DevicePlatform += itemP + ', ';
          });

          // remueve la ultima coma
          if (this._platform.platforms().length > 0) {
            this.CurrentDeviceInfo.DevicePlatform =
              this.CurrentDeviceInfo.DevicePlatform.substring(0, this.CurrentDeviceInfo.DevicePlatform.length - 2);
          }

          // cierra parentesis
          this.CurrentDeviceInfo.DevicePlatform += ')';

        }).catch((error) => { // hay error en la geolocalizacion

          this.CurrentDeviceInfo = {
            AppVersion: GlobalConstants.APP_VERSION,
            //
            WebAPIServerURL: WebAPIConstants.URLServer,
            WebAPIServerVersion: webAPIInfo.WebAPIServerVersion,
            WebAPIServerType: webAPIInfo.WebAPIServerVersion,
            //
            DBServer: webAPIInfo.DBServer,
            DBServerVersion: webAPIInfo.DBServerVersion,
            //
            DeviceUUID: deviceData.uuid,
            DeviceManufacturer: deviceData.manufacturer,
            DeviceModel: deviceData.model,
            DeviceOS: deviceData.operatingSystem,
            DeviceOSVersion: deviceData.osVersion,
            DevicePlatform: '',
            DeviceIPAddressPublic: webAPIInfo.DeviceIPAddressPublic,
            DeviceScreenResolution: this._platform.width() + 'x' + this._platform.height(),
            //
            DeviceLat: null,
            DeviceLng: null,
            DeviceAlt: null,
            DeviceAcu: null,
            DeviceSpeed: null,
            DeviceHeading: null,
            //
            BrowserName: webAPIInfo.BrowserName,
            BrowserVersion: webAPIInfo.BrowserVersion,
            //
            Token: ''
          };

          // da formato las plataformas desde _platform
          this.CurrentDeviceInfo.DevicePlatform += ' ('; // agrega parentesis
          this._platform.platforms().forEach((itemP)  => { // recorre para concatenar en un string
            this.CurrentDeviceInfo.DevicePlatform += itemP + ', ';
          });

          // remueve la ultima coma
          if (this._platform.platforms().length > 0) {
            this.CurrentDeviceInfo.DevicePlatform =
              this.CurrentDeviceInfo.DevicePlatform.substring(0, this.CurrentDeviceInfo.DevicePlatform.length - 2);
          }

          // cierra parentesis
          this.CurrentDeviceInfo.DevicePlatform += ')';

        });
      });
    });

    return res;
  }

  //#endregion

}
