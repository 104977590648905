import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

import { WebAPIService } from 'src/app/services/webapi/web-api.service';
import { WebAPIConstants } from 'src/app/services/globalConstants/global-constants.service';

import { DeviceInfoService } from 'src/app/services/deviceInfo/device-info.service';

import { IUsuario } from 'src/app/interfaces/IUsuario';
import { IUsuarioWeb } from 'src/app/interfaces/IUsuarioWeb';
import { StorageService } from '../storage/storage.service';
import { IRecovery } from 'src/app/interfaces/IRecovery';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

//#region Properties

  public CurrentUser: IUsuario = this.getNewUser();
  public RegistroValido: number;
  public RecoveryValido: number;
  public LoginValido: number;
  public PasswordActualizado: number;
  public AllPedidos: any;

  public scrollTop: number = 0;

//#endregion Properties

//#region Constructor

  constructor(private _webAPISrv: WebAPIService,
              private _deviceInfoSrv: DeviceInfoService, public storage: StorageService) {

  }

//#endregion Constructor

//#region Otros Metodos

  public getNewUser(): IUsuario {

    const newUsuario: IUsuario = {
      IdUsuario: 0,
      Usuario: '',
      UsuarioTxt: '',
      NumeroEmpleado: '',
      Nombre: '',
      Email: '',
      Telefono: '',
      Password: '',
      IdStatus: '',
      StatusTxt: '',
      Activo: false,
      UUID: '',
      FechaCreacion: '',
      FechaCreacionTxt: '',
      UsuarioCreacion: '',
      UsuarioCreacionTxt: '',
      IconVisible: false,
      IconName: '',
      IconNameIOs: '',
      IconNameMD: '',
      IconColor: '',
      Logged: false,
      Token: '',
      DesdeMain: false,
      Administrador: false,
      Publico: false,
      Usuarios: false,
      Configuracion: false,
      IdSucursal: '',
      SucursalTxt: '',
      TokenRecovery: ''
    };

    return newUsuario;
  }

  public login(usuario: string, password: string, UUID: string): Observable<any> {

    console.log('UsuariosService >> login');

    this.CurrentUser = this.getNewUser();

    this.CurrentUser.Usuario = usuario;
    this.CurrentUser.Password = password;
    this.CurrentUser.UUID = UUID;

    const url: string = WebAPIConstants.URLUsuarios + 'login';

    return this._webAPISrv.post(url, this.CurrentUser, '', false).pipe(map((newUser: IUsuario) => {
      this.CurrentUser = newUser;

      const existe: boolean = (this.CurrentUser.IdUsuario > 0);
      const activo: boolean = (this.CurrentUser.IdStatus == 'A');
      if (existe && activo) {

        // usuario ok
        this.CurrentUser.Logged = true;
      } else {
        // usuario not ok
        this.CurrentUser.Logged = false;
      }

      console.log('-------------------------------');
      console.log('IdUsuario: ' + this.CurrentUser.IdUsuario);
      console.log('Nombre: ' + this.CurrentUser.Nombre);
      console.log('IdStatus: ' + this.CurrentUser.IdStatus);
      console.log('Logged: ' + this.CurrentUser.Logged);
      console.log('Token: ' + this.CurrentUser.Token);
      console.log('----------- Permisos ----------');
      console.log('Administrador: ' + this.CurrentUser.Administrador);
      console.log('Publico: ' + this.CurrentUser.Publico);
      console.log('-------------------------------');
      console.log('Configuracion: ' + this.CurrentUser.Configuracion);
      console.log('Usuarios: ' + this.CurrentUser.Usuarios);
      console.log('---------------------------------------');
    }));

  }

  public register(usuario): Observable<any> {
    // construye estructura para registar usuario
    const newUsuario: IUsuarioWeb = {
      IdUsuario: 0,
      Nombre: usuario.user_name,
      Email: usuario.user_email,
      Telefono: usuario.user_phone,
      Domicilio: usuario.user_domicilio,
      Password: usuario.user_password,
      DeviceInfo: this._deviceInfoSrv.CurrentDeviceInfo,
    };

    // guarda usuario en API
    const url: string = WebAPIConstants.URLUsuarios + 'registroUsuario';
    return this._webAPISrv.post(url, newUsuario, '', false).pipe(map((user: any) => {
      this.RegistroValido = user;
      this.storage.setSesionData(user)
      .then(() => {
          this.LoginValido = 1;
      });
      console.log('-------------------------------------------');
      console.log('UsuarioService >> registroUsuario >> ' + user);
      console.log('-------------------------------------------');
    }));

}

public loginWeb(usuario): Observable<any> {
  // construye estructura para registar usuario
  const newUsuario: IUsuarioWeb = {
    IdUsuario: 0,
      Nombre: '',
      Email: usuario.user,
      Telefono: '',
      Domicilio: '',
      Password: usuario.password,
      DeviceInfo: this._deviceInfoSrv.CurrentDeviceInfo,
  };
  // guarda usuario en API
  const url: string = WebAPIConstants.URLUsuarios + 'loginWeb';
  return this._webAPISrv.post(url, newUsuario, '', false).pipe(map((resUser) => {
    if (resUser['IdUsuario'] > 0){
      console.log('desde');
      this.LoginValido = 1;
      this.storage.setSesionData(resUser)
                      .then(() => {
      });
    }
    else{
      this.LoginValido = 0;
    }
    // this._registrovalido = resUser;
    console.log('-------------------------------------------');
    console.log('UsuarioService >> login >> ');
    console.log(resUser['IdUsuario']);
    console.log('-------------------------------------------');
  }));

}

public validaRecovery(token): Observable<any> {
  const newRecovery: IRecovery = {
      Token: token,
      DeviceInfo: this._deviceInfoSrv.CurrentDeviceInfo,
      Password: ''
  };
  // guarda usuario en API
  const url: string = WebAPIConstants.URLUsuarios + 'validarecovery';
  return this._webAPISrv.post(url, newRecovery, '', false).pipe(map((validarecovery) => {
    if (validarecovery == 1){
      console.log('desde');
      this.RecoveryValido = 1;
    }
    else{
      this.RecoveryValido = 0;
    }
    // this._registrovalido = resUser;
    console.log('-------------------------------------------');
    console.log('UsuarioService >> validarecovery >> ');
    console.log(validarecovery);
    console.log('-------------------------------------------');
  }));

}

public updatePassword(password: string, tokenRecovery: string): Observable<any> {
  const dataRecovery: IRecovery = {
      Token: tokenRecovery,
      DeviceInfo: this._deviceInfoSrv.CurrentDeviceInfo,
      Password: password
  };
    // actualiza contraseña
  const url: string = WebAPIConstants.URLUsuarios + 'actualizaPassword';
  return this._webAPISrv.post(url, dataRecovery, '', true).pipe(map((res: any) => {
      this.PasswordActualizado = res;
      console.log('-------------------------------------------');
      console.log('UsuarioService >> actualizaPassword >> ' + res);
      console.log('-------------------------------------------');
    }));
}

  public logout(): void {
    console.log('UsuariosService >> logout');

    // resetea usuario actual
    this.CurrentUser = this.getNewUser();
  }

//#endregion Otros Metodos

//#region Token Público / Storage

  public async getPublicToken(): Promise<any> {

    // recupera el item del token guardado en el storage local
    return Storage.get({ key: 'token' });

  }

  public async setPublicToken(): Promise<any> {

    // genera token publico en WebAPI
    const url: string = WebAPIConstants.URLUsuarios + 'getPublicToken/' + this._deviceInfoSrv.CurrentDeviceInfo.DeviceUUID;
    const res = await this._webAPISrv.get(url, '', '', false).toPromise().then(async (token: string) => {

      // asigna token a variable local
      this._deviceInfoSrv.CurrentDeviceInfo.Token = token;

      // guarda toda la info en el storage local
      await Storage.set({
        key: 'token',
        value: JSON.stringify(this._deviceInfoSrv.CurrentDeviceInfo)
      });
    });

    return res;

  }

  public async delPublicToken(): Promise<void> {

    await Storage.remove({
      key: 'token'
    });

  }

  public async doPublicToken() {

    // Nota: Se borra el token local, para prueba
    // await this._usuarioSrv.delPublicToken();

    // revisa si hay token localmente
    const tokenItem = await this.getPublicToken();

    if (tokenItem.value == null) {
      // no hay token guardado localmente
      await this._deviceInfoSrv.getDeviceInfo(); // recupera info del device
      await this.setPublicToken(); // recupera token desde webAPI y guarda local
      // this.storage.validaProductosCart();
    } else {
      // si hay token guardado localmente
      this._deviceInfoSrv.CurrentDeviceInfo = JSON.parse(tokenItem.value);
      // this.storage.validaProductosCart();
    }

    console.log('-----------------------------------------------------');
    console.log('Token >> ' + JSON.stringify(this._deviceInfoSrv.CurrentDeviceInfo.Token));
    console.log('-----------------------------------------------------');
  }

  public async setLocation(lat, lng) {
    console.log('guardando domicilio actual');
    console.log(lat);
    console.log(lng);
    const urlDomi = 'https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=' + lat + '&longitude=' + lng + '&localityLanguage=es';
    this._webAPISrv.getApp(urlDomi)
            .subscribe(async (res) =>
            {
              console.log('domicilio recibido', res);
              await Storage.set({
                  key: 'dataLocation',
                  value: JSON.stringify({
                    Address: res['locality'],
                    Latitude: res['latitude'],
                    Longitude: res['longitude']
                  })
              });
            }
            , (err) =>
            {
              console.log(err);
            });
    return 1;
  }

  public async getLocation(){
    console.log('trayendo e domi');
    const ret = await Storage.get({ key: 'dataLocation' });
    const loc = JSON.parse(ret.value);
    if (loc) {
            return loc;
        }
    return 0;
  }

  public getPedidos() {
    const url: string = WebAPIConstants.URLUsuarios + 'getPedidos/';
    const deviceUUID: string = this._deviceInfoSrv.CurrentDeviceInfo.DeviceUUID;
    const token: string = this._deviceInfoSrv.CurrentDeviceInfo.Token;
    return this._webAPISrv.get(url, deviceUUID, token, true).pipe(map((res) => {
      this.AllPedidos = res;
      console.log('-------------------------------------------');
      console.log('UsuarioService >> getPedidos >> '  + res);
      console.log('-------------------------------------------');
    }));
  }

//#endregion

}
