import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const allRoutes: Routes = [
  {
    path: '',
    redirectTo: 'inicio',
    pathMatch: 'full'
  },
  {
    path: 'inicio',
    loadChildren: './pages/inicio/inicio.module#InicioPageModule'
  },
  {
    path: 'nosotros',
    loadChildren: './pages/nosotros/nosotros.module#NosotrosPageModule'
  },
  {
    path: 'promocion',
    loadChildren: './pages/promocion/promocion.module#PromocionPageModule'
  },
  {
    path: 'fanClub',
    loadChildren: './pages/fanClub/fan-club.module#FanClubPageModule'
  },
  {
    path: 'categoria',
    loadChildren: './pages/categoria/categoria.module#CategoriaPageModule'
  },
  {
    path: 'categoriaDetalle',
    loadChildren: './pages/categoriaDetalle/categoria-detalle.module#CategoriaDetallePageModule'
  },
  {
    path: 'producto',
    loadChildren: './pages/producto/producto.module#ProductoPageModule'
  },
  {
    path: 'sucursal',
    loadChildren: './pages/sucursal/sucursal.module#SucursalPageModule'
  },
  {
    path: 'contacto',
    loadChildren: './pages/contacto/contacto.module#ContactoPageModule'
  },
  {
    path: 'carrito',
    loadChildren: './pages/carrito/carrito.module#CarritoPageModule'
  },
  {
    path: 'login',
    loadChildren: './pages/login/login.module#LoginPageModule'
  },
  {
    path: 'recovery',
    loadChildren: './pages/recovery/recovery.module#RecoveryPageModule'
  },
  {
    path: 'inicio2',
    loadChildren: './pages/inicio2/inicio2.module#Inicio2PageModule'
  },
  {
    path: 'test',
    loadChildren: './pages/test/test.module#TestPageModule'
  },
  {
    path: 'releaseNotes',
    loadChildren: './pages/releaseNotes/release-notes.module#ReleaseNotesPageModule'
  },
  {
    path: 'blank',
    loadChildren: './pages/blank/blank.module#BlankPageModule'
  },
  {
    path: 'home',
    loadChildren: './pages/home/home.module#HomePageModule'
  },
  {
    path: 'account',
    loadChildren: './pages/account/account.module#AccountPageModule'
  },
  {
    path: 'checkout',
    loadChildren: './pages/checkout/checkout.module#CheckoutPageModule'
  },
  {
    path: 'ventas',
    loadChildren: './pages/ventas/ventas.module#VentasPageModule'
  },
  // {
  //   path: 'mantenimiento',
  //   loadChildren: './pages/mantenimiento/mantenimiento.module#MantenimientoPageModule'
  // },
  {
    path: '**',
    // loadChildren: './pages/mantenimiento/mantenimiento.module#MantenimientoPageModule',
    loadChildren: './pages/inicio/inicio.module#InicioPageModule'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(allRoutes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
