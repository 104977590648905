import { Injectable } from '@angular/core';
import { IGlobalConstants } from 'src/app/interfaces/IGlobalConstants';
import { IWebAPIConstants } from 'src/app/interfaces/IWebAPIConstants';
// import { url } from 'inspector';

///////////////////////////////////////////////////////////////////
const urlWebAPIServer: string = 'https://pastelerialasalle.com.mx/webapi/'; //  Produccion (Web)
// const urlWebAPIServer: string = 'http://localhost:51558/'; // Web Desarrollo
// const urlWebAPIServer: string = 'http://localhost/LaSalleWeb/WebAPI/'; // Web Desarrollo / Tablet Desarrollo
///////////////////////////////////////////////////////////////////

export const GlobalConstants: IGlobalConstants = {
  APP_VERSION: 'v1.1.0.210630.2200',
  //
  DEVELOPMENT_ENABLED: false,
  DEVELOPMENT_USER: 'soporte',
  DEVELOPMENT_PWD: 'cosmic',
  TOAST_DURATION_TIME: 1.5 * 1000, // 1.5 seg
  TOAST_DURATION_TIME_SLOW: 10 * 1000, // 10 seg
  TOAST_DURATION_TIME_FAST: 0.1 * 1000, // 0.1 seg
  //
  CATEGORIA_PASTEL: 'PAST',
  CATEGORIA_PAY: 'PAY',
  CATEGORIA_EMPANADA: 'EMP',
  CATEGORIA_REPOSTERIA: 'REPOS',
  CATEGORIA_QUEQUITOS: 'QUEQ',
  CATEGORIA_REBANADAS_INDIVIDUALES: 'REB',
  CATEGORIA_PAN: 'PAN',
  CATEGORIA_VELAS: 'VELAS',
  CATEGORIA_DESECHABLES: 'DES',
  CATEGORIA_ARTICULOS_DECORACION: 'DECO',
  CATEGORIA_COMPRA_VENTA: 'CV',
  CATEGORIA_OTROS_ALIMENTOS: 'ALIM',
  CATEGORIA_SIN_CATEGORIA: 'SC',
  CATEGORIA_OTROS: 'OTROS',
  //
  SEGMENTO_PREMIUM: 'PREM',
  SEGMENTO_TRADICIONAL: 'TRAD',
  SEGMENTO_CASERO: 'CAS',
  SEGMENTO_POSTRE: 'POST',
  SEGMENTO_REPOSTERIA: 'REP',
  SEGMENTO_QUEQUITOS: 'QUEQ',
  SEGMENTO_REBANADAS: 'REB',
  SEGMENTO_TEMPORADA: 'TEMP',
  //
  TIPO_DESCUENTO_PORCENTAJE: 'PORC',
  TIPO_DESCUENTO_MONTO: 'MONTO',
  //
  PROMO_STATUS_ACTIVA: 'A',
  PROMO_STATUS_INACTIVA: 'I',
  PROMO_STATUSTXT_ACTIVA: 'Activa',
  PROMO_STATUSTXT_INACTIVA: 'Inactiva',
  //
  PROMO_TIPO_CAT_PROD_SEG_CATEGORIA: 'CAT',
  PROMO_TIPO_CAT_PROD_SEG_PRODUCTO: 'PROD',
  PROMO_TIPO_CAT_PROD_SEG_SEGMENTO: 'SEG',
  //
  PROMO_TIPO_PROMO_MONTO: 'MONTO',
  PROMO_TIPO_PROMO_PORCENTAJE: 'PORC',
  PROMO_TIPO_PROMO_PORCENTAJE_2o_PRODUCTO: 'PORC2',
  PROMO_TIPO_PROMO_CUMPLEAÑERO: 'CUMPL',
  PROMO_TIPO_PROMO_CUPON: 'CUPON',
  //
  PROMO_TIPO_COMPRA_DESC_ITEM_COMPRA: 'COM',
  PROMO_TIPO_COMPRA_DESC_ITEM_DESCUENTO: 'DESC',
  PROMO_TIPO_COMPRA_DESC_ITEM_REGALO: 'REG',
  //
  URL_FACEBOOK: 'https://www.facebook.com/pastelerialasalle',
  URL_INSTAGRAM: 'https://www.instagram.com/pasteleria_lasalle',
};

///////////////////////////////////////////////////////////////////
// Configuración del WebAPI.
///////////////////////////////////////////////////////////////////
export const WebAPIConstants: IWebAPIConstants = {
  URLServer: urlWebAPIServer,
  URLSoporte: urlWebAPIServer + 'soporte/',
  URLError: urlWebAPIServer + 'error/',
  URLUsuarios: urlWebAPIServer + 'usuario/',
  URLUsuariosToken: urlWebAPIServer + 'Token/',
  URLUsuariosRegistrar: urlWebAPIServer + 'Account/Register/',
  //
  URLExistencias: urlWebAPIServer + 'existencias/',
  //
  URLProducto: urlWebAPIServer + 'producto/',
  URLProductoImagenes: urlWebAPIServer + 'images/productos/',
  //
  URLVisita: urlWebAPIServer + 'visita/',
  //
  URLSlider: urlWebAPIServer + 'slider/',
  URLSliderImagenes: urlWebAPIServer + 'images/slider/',
  //
  URLCatalogo: urlWebAPIServer + 'catalogo/',
  URLSucursal: urlWebAPIServer + 'sucursal/',
  URLCategoria: urlWebAPIServer + 'categoria/',
  URLConfiguracion: urlWebAPIServer + 'configuracion/',
  URLReportes: urlWebAPIServer + 'reportes/',
  //
  URLPromocionPoS: urlWebAPIServer + 'promocion/',
  URLPromocionImagenes: urlWebAPIServer + 'images/promociones/',
  //
  URLCarrito: urlWebAPIServer + 'carrito/',
  URLVentaEnLinea: urlWebAPIServer + 'ventaEnLinea/',
  //
  ResponseOk: 'OK'
};

@Injectable({
  providedIn: 'root'
})
export class GlobalConstantsService {
  constructor() { }
}
