import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WebAPIService {

  constructor(public _http: HttpClient) { }

  public get(url: string, deviceUUID: string, token: string, showConsole: boolean): Observable<any> {

    // si el url no tiene diagonal al final, se la agrega
    if (!url.endsWith('/')) {
      url += '/';
    }

    // agrega token en URL
    if (deviceUUID !== '') { url += deviceUUID; }
    if (!url.endsWith('/')) { url += '/'; }
    if (token !== '') { url += token; }

    if (showConsole) {
      console.log('WebAPIService >> Get >> WebAPI Call >> ' + url);
    }

    return this._http.get(url).pipe(map(res => {
      return res;
    }));
  }

  public post(url: string, item: any, token: string, showConsole: boolean): Observable<any> {

    // Hace JSON el item
    const body: string = JSON.stringify(item);

    // si el url no tiene diagonal al final, se la agrega
    if (!url.endsWith('/')) {
      url += '/';
    }

    // agrega token en URL
    url += token;

    if (showConsole) {
      console.log('WebAPIService >> Post >> WebAPI Call >> ' + url);
      console.log('WebAPIService >> Post >> Body >> ' + body);
    }

    return this._http.post(url, body, {headers: {'Content-Type': 'application/json;charset=UTF-8'}}).pipe(map(
      res => {
        return res;
      },
      (err: HttpErrorResponse) => {
        console.log('WebAPIService >> Post >> Error: ' + err.error);
        console.log('WebAPIService >> Post >> Name: ' + err.name);
        console.log('WebAPIService >> Post >> Message: ' + err.message);
        console.log('WebAPIService >> Post >> Status: ' + err.status);
      }
    ));
  }

  
  public getApp(url: string) {

    return this._http.get(url);

/*
  return this._http.get(url)
  .subscribe((res) => 
  {
    return res;
  }
  ,(err) => 
  {
    return err;
  });*/
}
}

