// tslint:disable: deprecation
// tslint:disable: only-arrow-functions
import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Title } from '@angular/platform-browser';

import { Platform, NavController, MenuController, ModalController, LoadingController } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { NavigationBar } from '@ionic-native/navigation-bar/ngx';

import { GlobalConstants } from './services/globalConstants/global-constants.service';

import { StorageService } from './services/storage/storage.service';

// tslint:disable-next-line: ban-types
declare let fbq: Function; // Definicion de función para FB Pixel

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  //#region " Constructor "

  constructor(public _platform: Platform,
              public _splashScreen: SplashScreen,
              public _statusBar: StatusBar,
              public _screenOrientation: ScreenOrientation,
              public _navigationBar: NavigationBar,
              public _navCtrl: NavController,
              public _menuCtrl: MenuController,
              public _modalCtrl: ModalController,
              public _loadingCtrl: LoadingController,
              public _storage: StorageService,
              private _router: Router,
              public _titleSrv: Title) {

    this.initializeApp();

    // deshabilita los mensajes de la consola (log y errores)
    if ((!GlobalConstants.DEVELOPMENT_ENABLED) && (window)) {
      window.console.log = function() { };
      window.console.info = function() { };
      window.console.error = function() { };
    }

    if (GlobalConstants.DEVELOPMENT_ENABLED) {
      this._titleSrv.setTitle('Pastelería La Salle | ' + this._platform.width() + 'x' + this._platform.height());
    }

    // registra evento de cambio de pagina
    this._router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        fbq('track', 'PageView'); // FB Pixel
      }
    });
  }

//#endregion

//#region " Metodos NG "

  public initializeApp() {

    this._platform.ready().then(() => {
      if (this._platform.is('mobile')) {
        this._screenOrientation.lock(this._screenOrientation.ORIENTATIONS.LANDSCAPE);
        this._statusBar.hide();
        this._statusBar.styleDefault();
        this._statusBar.overlaysWebView(true);
        this._splashScreen.hide();
        this._navigationBar.setUp(true);
      }

      this._storage.validaSesionOnLoad().then(() => {});

    });
  }

//#endregion

}
