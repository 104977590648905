import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule, Route } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { NavigationBar } from '@ionic-native/navigation-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Font Awesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

// ng-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PedidoDetallePage } from './pages/pedidoDetalle/pedidoDetalle.page';
import { PedidoDetalleClientePage } from './pages/pedidoDetalleCliente/pedidoDetalleCliente.page';

import { FormsModule } from '@angular/forms';

library.add(fas, far, fab);

@NgModule({
  declarations: [
    AppComponent,
    PedidoDetallePage,
    PedidoDetalleClientePage
  ],
  entryComponents: [
    PedidoDetallePage,
    PedidoDetalleClientePage],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    NgbModule,
    FormsModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    ScreenOrientation,
    NavigationBar,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
