import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { WebAPIService } from 'src/app/services/webapi/web-api.service';
import { WebAPIConstants } from 'src/app/services/globalConstants/global-constants.service';
import { DeviceInfoService } from 'src/app/services/deviceInfo/device-info.service';

import { ICarritoWeb } from 'src/app/interfaces/ICarritoWeb';
import { Observable } from 'rxjs';
import { ICarritoProductoWeb } from 'src/app/interfaces/ICarritoProductoWeb';
import { IPedido } from 'src/app/interfaces/IPedido';
import { IVentaWeb } from 'src/app/interfaces/IVentaWeb';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class VentaService {

  //#region Propiedades

  public ventas: Array<ICarritoWeb> = [];

  public VentaConfirmada: number;

  public _statusActualizado: number;

  //#endregion

  //#region Constructor

  constructor(private _webAPISrv: WebAPIService,
              private _deviceInfoSrv: DeviceInfoService,
              private _storageSrv: StorageService) { }

  //#endregion

  public getAllVentas() {
    const url: string = WebAPIConstants.URLVentaEnLinea + 'getAllVentas/';
    const deviceUUID: string = this._deviceInfoSrv.CurrentDeviceInfo.DeviceUUID;
    console.log('toda la info device');
    console.log(this._deviceInfoSrv.CurrentDeviceInfo);
    const token: string = this._deviceInfoSrv.CurrentDeviceInfo.Token;
    return this._webAPISrv.get(url, deviceUUID, token, true).pipe(map((Item: Array<ICarritoWeb>) => {
      this.ventas = Item;
      console.log('-------------------------------------------');
      console.log('VentaService >> getAllVentas >> ' + Item.length);
      console.log('-------------------------------------------');
    }));
  }

  public guardar(pedido: IPedido): Observable<any> {

    // guarda producto/carrito en API
    const url: string = WebAPIConstants.URLVentaEnLinea + 'guardar';
    return this._webAPISrv.post(url, pedido, '', false).pipe(map((res) => {
      this.VentaConfirmada = res;
      console.log('-------------------------------------------');
      console.log('CarritoService >> guardarVentaEnLinea >> ' + res);
      console.log('-------------------------------------------');
    }));

  }

  public updateStatus(idVenta: string, idStatus: string): Observable<any> {
    const statusWeb: IVentaWeb = {
      IdStatusVentaWeb: idStatus,
      IdVenta: idVenta,
      Motivo: '',
      DeviceInfo: this._deviceInfoSrv.CurrentDeviceInfo,
    };

    // actualiza status de venta web
    const url: string = WebAPIConstants.URLVentaEnLinea + 'updateStatus';
    return this._webAPISrv.post(url, statusWeb, '', false).pipe(map((res: number) => {
      this._statusActualizado = res;
      console.log('-------------------------------------------');
      console.log('VentaService >> updateStatusVenta >> ' + res);
      console.log('-------------------------------------------');
    }));

  }

  public cancelar(idVenta, mensaje): Observable<any> {

    // construye estructura para actualizar el mensaje
    const cancelItem: IVentaWeb = {
      IdVenta: idVenta,
      IdStatusVentaWeb: 'CAN',
      DeviceInfo: this._deviceInfoSrv.CurrentDeviceInfo,
      Motivo: mensaje,
    };

    // guarda visita en API
    const url: string = WebAPIConstants.URLVentaEnLinea + 'cancelar';
    return this._webAPISrv.post(url, cancelItem, '', false).pipe(map((idCart: number) => {
      console.log('-------------------------------------------');
      console.log('VentaService >> cancelaVenta >> ' + idCart);
      console.log('-------------------------------------------');
    }));

  }

}
