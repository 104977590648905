// tslint:disable: max-line-length
import { Component,  OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GlobalConstants } from 'src/app/services/globalConstants/global-constants.service';
import { GlobalFunctionsService } from 'src/app/services/globalFunctions/global-functions.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { IProductoWeb } from 'src/app/interfaces/IProductoWeb';

import { NavController, PopoverController, ToastController } from '@ionic/angular';
import { VentaService } from 'src/app/services/venta/venta.service';

@Component({
  selector: 'app-cancelar-popover',
  templateUrl: './cancelar-popover.component.html',
  styleUrls: ['./cancelar-popover.component.scss'],
})
export class CancelarPopoverComponent implements OnInit {

  //#region Propiedades

  public _appVersion: string;

  public _idVenta: number;
  public _motivo: any;

  //#endregion Propiedades

  //#region Constructor

  constructor(private _usuarioSrv: UsuarioService,
              private _ventaSrv: VentaService,
              private _globalFunctionsSrv: GlobalFunctionsService,
              private _popoverCtrl: PopoverController, ) {

    console.log('CancelarPopoverComponent >> constructor');

  }

  //#endregion Constructor

  //#region Eventos NG

  public ngOnInit() {
    console.log('ProductoMensajePopoverComponent >> ngOnInit');
  }

  public cancelar(){
    this._popoverCtrl.dismiss();
  }

  public refreshValue($event){
    this._motivo = $event.target.value;
  }

  public guardar(){
   this._ventaSrv.cancelar(this._idVenta, this._motivo).subscribe(() => {
      this._globalFunctionsSrv.showMessageToast('Su venta se canceló exitósamente.');
      const resp = {
        update: true
      };
      this._popoverCtrl.dismiss({ resp });
    });
  }

  public async ionViewDidEnter() {
    console.log('ProductoMensajePopoverComponent >> ionViewDidEnter');

    this._appVersion = GlobalConstants.APP_VERSION;

    // recupera/crea/guarda token publico
    await this._usuarioSrv.doPublicToken();
  }

  //#endregion

  //#region Eventos Botones

  public btnItemClick(item: IProductoWeb) {

  }

  //#endregion

  //#region Otros Metodos
  //#endregion

}
